import { FilteredCreditApplication } from '@src/api/credit-api'
import { Constants } from '@src/types'
import { useTranslation } from 'react-i18next'

interface Props {
  creditApp: FilteredCreditApplication
}
const MonthlyPaymentEstimationNotice = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const hasPromotion = creditApp.merchantPaymentPlanId !== null
  return (
    <span style={{ fontSize: '1.5rem' }}>
      <sup style={{ color: 'red' }}>*</sup>
      {t('common.monthlyAmountsInclude', {
        financeFee: hasPromotion
          ? creditApp.merchantPaymentPlan.borrowerFeeRate
          : Math.round(Constants.FinanceFeeRateForRegular * 100),
        interestRate: creditApp?.finalDecision.interestRate,
      })}
    </span>
  )
}

export default MonthlyPaymentEstimationNotice
