import {
  FormatCurrency,
  FormatCurrencyNoDecimals,
  FormatCurrencyToNearestUpperMultipleToNoDecimals,
} from '@src/services/Formatter'
import { Constants, EPaymentFrequency, EWorksheetStatus } from '@src/types/constants'
import { ChangeEventHandler, FormEventHandler, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useGetPromotionalCreditAppPaymentAmount } from '@src/api/compute-api'
import { EditWorksheetDto, FilteredCreditApplication, useUpdateWorksheet } from '@src/api/credit-api'
import { AsyncActionButton, CancelAppButton, Loader } from '@src/components'
import ManageCoappButton from '@src/components/ManageCoappButton'
import RadioButtonList from '@src/components/RadioButtonList'
import { useTermAmountOptions } from '@src/data/credit-application-hooks'
import { getLoanAmount, hasFlexPlan } from '@src/data/credit-application-selectors'
import { reportErrorToConsole } from '@src/services/error-logger'
import { ETrackedEvents, useTrackStepReachedEvent } from '@src/services/trackingEvents'
import { useParams } from 'react-router-dom'
import useTermPaymentsTable from './hooks'
import MonthlyPaymentEstimationNotice from '../components/MonthlyPaymentEstimationNotice'

type Props = {
  creditApp: FilteredCreditApplication
}
const InitialApprobationForm = ({ creditApp }: Props) => {
  const { t, i18n } = useTranslation()
  const params = useParams()
  const id = params.id as string
  const [selectedTerm, setSelectedTerm] = useState(-1)
  const [hasFormError, setHasFormError] = useState(false)
  const hasPromotion = creditApp.merchantPaymentPlanId !== null
  const [monthlyPaymentPerMonths, isLoadingTermTable] = useTermPaymentsTable(creditApp)
  const [promotionalAmounts, isLoadingPromoAmounts] = useGetPromotionalCreditAppPaymentAmount(
    id,
    hasPromotion,
    creditApp.requestedLoanAmount,
  )
  const [submitWorksheetUpdate, isSubmitting] = useUpdateWorksheet()
  const termOptions = useTermAmountOptions(monthlyPaymentPerMonths, i18n.language)

  useTrackStepReachedEvent(creditApp, ETrackedEvents.QualifSuccess)

  const exponent = creditApp.merchantPaymentPlan?.borrowerFeeRate > 0 ? '1' : ''
  const hasFlex = hasFlexPlan(creditApp)
  const hasAdminFee = creditApp.merchantPaymentPlan?.borrowerFeeRate > 0
  const promotionText = hasFlex ? 'decision.hardhitDecisionApprovedFlex' : 'decision.hardhitDecisionApprovedFlash'

  const submitTerm: FormEventHandler = (e) => {
    e.preventDefault()
    if (creditApp && (selectedTerm > -1 || hasPromotion)) {
      const term = hasPromotion ? creditApp.merchantPaymentPlan.loanTerm : selectedTerm

      const dto: EditWorksheetDto = {
        term,
        amountRequested: getLoanAmount(creditApp),
        creditApplicationId: creditApp.id,
        paymentFrequency: EPaymentFrequency.Monthly,
        deliveryOn: null,
        firstPaymentOn: null,
        includeInsurance: false,
        status: EWorksheetStatus.Draft,
        create: creditApp.worksheet === null,
      }
      submitWorksheetUpdate(dto).catch(reportErrorToConsole)
    }
  }

  const getTitle: string = useMemo(() => {
    const hasSkipIncomeVerification =
      creditApp.applicant.hasSkippedIncomeValidation &&
      (creditApp.coapplicant != null ? creditApp.coapplicant.hasSkippedIncomeValidation : true)
    return hasSkipIncomeVerification
      ? t('decision.hardhitDecisionApprovedTitle')
      : t('decision.hardhitDecisionConditionallyApprovedTitle')
  }, [creditApp, t])

  const handleButtonClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (selectedTerm === -1 && !hasPromotion) {
      setHasFormError(true)
    } else {
      submitTerm(e)
    }
  }

  const handleTermSelected: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const newTerm = parseInt(e.currentTarget.value, 10)
      setSelectedTerm(newTerm)
      setHasFormError(false)
    },
    [setSelectedTerm],
  )

  if (isLoadingPromoAmounts || isLoadingTermTable || !creditApp)
    return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  return (
    <main className="general-message" style={{ display: 'block' }}>
      <form onSubmit={submitTerm}>
        <div id="loan-form-container">
          <h3>{getTitle}</h3>
          <div className="paragraph">
            {hasPromotion && promotionalAmounts?.paymentForFrequency && (
              <p>
                <Trans
                  i18nKey={promotionText}
                  values={{
                    maximumAmount: FormatCurrencyNoDecimals(creditApp.finalDecision.maxAmountFinanced),
                    reducedInterestRate: creditApp.merchantPaymentPlan.reducedInterestRate,
                    reducedRateDurationInMonths: creditApp.merchantPaymentPlan.reducedRateDurationInMonths,
                  }}
                />
                !{hasFlex && <sup style={{ fontSize: '1rem', color: 'red' }}>1</sup>}
                &nbsp;
                <Trans
                  i18nKey="decision.estimatedPayment"
                  values={{
                    monthlyPayment: FormatCurrencyToNearestUpperMultipleToNoDecimals(
                      promotionalAmounts.paymentForFrequency,
                      5,
                    ),
                  }}
                />
                .{hasFlex && hasAdminFee && <sup style={{ fontSize: '1rem', color: 'red' }}>2</sup>}
                {!hasFlex && exponent && hasAdminFee && <sup style={{ fontSize: '1rem', color: 'red' }}>1</sup>}
              </p>
            )}

            {!hasPromotion && monthlyPaymentPerMonths && monthlyPaymentPerMonths.length > 0 && (
              <>
                <p>
                  <Trans
                    i18nKey="decision.hardhitDecisionApproved"
                    values={{
                      maximumAmount: FormatCurrencyNoDecimals(creditApp.finalDecision.maxAmountFinanced),
                      minPaymentAmount: FormatCurrency(
                        monthlyPaymentPerMonths[monthlyPaymentPerMonths.length - 1].amount,
                      ),
                    }}
                  />
                </p>
                <div className={`radiobuttons-stacked-container ${hasFormError && 'error'}`}>
                  <h3>{t('loan.selectLoanPeriod')}</h3>
                  <div>
                    <RadioButtonList
                      name="term"
                      onChange={handleTermSelected}
                      options={termOptions}
                      defaultValue={selectedTerm.toString()}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="btn-group mobile-reversed">
            <CancelAppButton creditAppId={id} />
            <ManageCoappButton creditAppId={id} useChangeLabel={creditApp.coapplicant !== null} />
            <AsyncActionButton
              type="submit"
              className="btn btn-blue"
              isPending={isSubmitting}
              primary
              onClick={handleButtonClicked}
            >
              {t('finalDecision.continue')}
              <i className="fa-regular fa-arrow-right" />
            </AsyncActionButton>
          </div>

          <div style={{ marginTop: '4rem' }}>
            {hasPromotion && hasFlex && (
              <div style={{ fontSize: '1.5rem' }}>
                <sup style={{ fontSize: '1rem', color: 'red' }}>1</sup>
                &nbsp;
                <Trans
                  dangerouslySetInnerHTML
                  i18nKey="decision.flexRemainder"
                  values={{
                    months:
                      creditApp.merchantPaymentPlan.loanTerm -
                      creditApp.merchantPaymentPlan.reducedRateDurationInMonths,
                    interestRate: creditApp.merchantPaymentPlan.interestRate,
                  }}
                />
              </div>
            )}

            {(!hasPromotion || exponent !== '') && hasAdminFee && (
              <div style={{ fontSize: '1.5rem' }}>
                <sup style={{ fontSize: '1rem', color: 'red' }}>{hasFlex ? '2' : '1'}</sup>
                &nbsp;
                {t('decision.includedFee', {
                  adminFeeRate: hasPromotion
                    ? creditApp.merchantPaymentPlan.borrowerFeeRate
                    : Math.round(Constants.FinanceFeeRateForRegular * 100),
                })}
              </div>
            )}
            {!hasPromotion && !hasAdminFee && <MonthlyPaymentEstimationNotice creditApp={creditApp} />}
          </div>
        </div>
      </form>
    </main>
  )
}

export default InitialApprobationForm
