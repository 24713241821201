import { Applicant, Prequalification } from '@src/containers/SubmitNewApp/components/PrequalificationSchema'
import { EApplicantType, EDocumentStatus, ESupplementalLoanRefusalReasons } from '@src/types'
import {
  MutationFunction,
  QueryFunctionContext,
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'
import apiClient from './api-client'
import { transformApplicantToApi, transformPrequalToApi } from './credit-transform'
import {
  CreditApplicationDocument,
  FilteredCreditApplication,
  RequiredExternalStep,
} from './types/FilteredCreditApplication'
import { FilteredWorksheet } from './types/FilteredWorksheet'
import { UploadFilesDto, UploadFilesResultDto, uploadFiles } from './upload-files-api'

export * from './types/FilteredCreditApplication'
export * from './types/FilteredWorksheet'

const SCOPE = 'credit-application'
const DETAIL = 'detail'
const SUPPLEMENTALLOAN = 'supplemental-loan'

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
  supplementalLoan: (id: string) => [{ scope: SCOPE, entity: SUPPLEMENTALLOAN, id }] as const,
}

const getCreditApplication = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const response = await apiClient.get<FilteredCreditApplication>(`/api/creditApplication/${encodeURIComponent(id)}`)
  return response.data
}

export function useGetCreditApplicationById(
  creditApplicationId: string,
  shouldPoll: boolean,
): [
  FilteredCreditApplication | null,
  boolean,
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<FilteredCreditApplication, Error>>,
] {
  const { isFetching, data, refetch } = useQuery({
    queryKey: keysFactory.detail(creditApplicationId),
    queryFn: getCreditApplication,
    enabled: !!creditApplicationId,
    refetchInterval: shouldPoll ? 5000 : undefined,
  })

  return [data ?? null, isFetching, refetch]
}

export type SubmitCoapplicantDto = {
  recaptchaToken: string
  creditApplicationId: string
  coapplicant: Applicant
}

const submitCoapplicant: MutationFunction<FilteredCreditApplication, SubmitCoapplicantDto> = async (
  dto: SubmitCoapplicantDto,
) => {
  const options: AxiosRequestConfig = {
    headers: {
      'x-recaptcha-token': dto.recaptchaToken,
    },
  }
  const coapplicant = transformApplicantToApi(dto.coapplicant)
  const response = await apiClient.post(
    `api/creditApplication/${dto.creditApplicationId}/coapplicant`,
    coapplicant,
    options,
  )
  return response.data as FilteredCreditApplication
}

export function useSubmitCoapplicant(): [
  MutationFunction<FilteredCreditApplication, SubmitCoapplicantDto>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: submitCoapplicant,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

export type SubmitPrequalificationDto = {
  recaptchaToken: string
  prequalification: Prequalification
}

const submitPrequalification: MutationFunction<FilteredCreditApplication, SubmitPrequalificationDto> = async (
  dto: SubmitPrequalificationDto,
) => {
  const options: AxiosRequestConfig = {
    headers: {
      'x-recaptcha-token': dto.recaptchaToken,
    },
  }
  const prequal = transformPrequalToApi(dto.prequalification)
  const response = await apiClient.post(`api/creditapplication/prequalify`, prequal, options)
  return response.data as FilteredCreditApplication
}

export function useSubmitPrequalification(): [
  MutationFunction<FilteredCreditApplication, SubmitPrequalificationDto>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: submitPrequalification,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

export type SubmitHardHitDto = {
  id: string
  applicantSin?: string | null | undefined
  coapplicantSin?: string | null | undefined
  requestedLoanAmount: number
}

const submitHardHit: MutationFunction<FilteredCreditApplication, SubmitHardHitDto> = async (creditApp) => {
  const response = await apiClient.put(`api/creditApplication/${creditApp.id}/submit`, creditApp)
  return response.data as FilteredCreditApplication
}

export function useSubmitHardHit(): [
  MutationFunction<FilteredCreditApplication, SubmitHardHitDto>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: submitHardHit,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

const removeCoapplicant: MutationFunction<FilteredCreditApplication, string> = async (creditApplicationId: string) => {
  const response = await apiClient.delete(`api/creditApplication/${creditApplicationId}/coapplicant`)

  return response.data as FilteredCreditApplication
}

export function useRemoveCoapplicant(): [MutationFunction<FilteredCreditApplication, string>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: removeCoapplicant,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

export type EditWorksheetDto = {
  amountRequested: number
  deliveryOn: string | null
  firstPaymentOn: string | null
  paymentFrequency: string
  term: number
  includeInsurance: boolean
  creditApplicationId: string
  status: string
  create: boolean
}

const updateWorksheet: MutationFunction<FilteredWorksheet, EditWorksheetDto> = async (dto: EditWorksheetDto) => {
  const response = dto.create
    ? await apiClient.post(`api/creditApplication/${dto.creditApplicationId}/worksheet`, dto)
    : await apiClient.put(`api/creditApplication/${dto.creditApplicationId}/worksheet`, dto)

  return response.data as FilteredWorksheet
}

export function useUpdateWorksheet(): [MutationFunction<FilteredWorksheet, EditWorksheetDto>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: updateWorksheet,
    onSuccess: (data, variables) => {
      const updated = {
        ...queryClient.getQueryData(keysFactory.detail(variables.creditApplicationId)),
      } as FilteredCreditApplication
      updated.worksheet = data
      queryClient.setQueryData(keysFactory.detail(variables.creditApplicationId), updated)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

const cancelApplication: MutationFunction<FilteredCreditApplication, string> = async (id: string) => {
  const response = await apiClient.put(`api/creditApplication/${id}/cancel`)
  return response.data as FilteredCreditApplication
}

export function useCancelApplication(): [MutationFunction<FilteredCreditApplication, string>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: cancelApplication,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

export function useUploadFiles(): [MutationFunction<UploadFilesResultDto, UploadFilesDto>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: uploadFiles,
    onSuccess: (data, variables) => {
      const updatedCreditApp = {
        ...queryClient.getQueryData(keysFactory.detail(data.creditApplicationId)),
      } as FilteredCreditApplication
      const doc: CreditApplicationDocument = {
        applicantType: variables.applicantType,
        status: EDocumentStatus.AwaitingApproval,
        subKey: variables.subKey,
        refusalReason: null,
        typeId: variables.typeId,
      }

      updatedCreditApp.documents = [...updatedCreditApp.documents]
      const existingDocIdx = updatedCreditApp.documents.findIndex(
        (x) =>
          x.typeId === variables.typeId && x.subKey === variables.subKey && x.applicantType === variables.applicantType,
      )
      if (existingDocIdx > -1) {
        updatedCreditApp.documents[existingDocIdx] = doc
      } else {
        updatedCreditApp.documents.push(doc)
      }

      queryClient.setQueryData(keysFactory.detail(data.creditApplicationId), updatedCreditApp)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}
export type DeleteBankAccountRequestDto = {
  id: string
  applicantType: EApplicantType
}

const resetApplicantBankRequest: MutationFunction<FilteredCreditApplication, DeleteBankAccountRequestDto> = async (
  dto: DeleteBankAccountRequestDto,
) => {
  const response = await apiClient.delete(`api/creditApplication/${dto.id}/bankrequest/${dto.applicantType}`)
  return response.data as FilteredCreditApplication
}

export function useResetApplicantBankRequest(): [
  MutationFunction<FilteredCreditApplication, DeleteBankAccountRequestDto>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: resetApplicantBankRequest,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

const continueWithComputedIncome: MutationFunction<
  FilteredCreditApplication,
  { id: string; applicantType: string }
> = async (dto) => {
  const response = await apiClient.post(
    `api/creditApplication/${dto.id}/ContinueWithComputedIncome/${dto.applicantType}`,
  )
  return response.data as FilteredCreditApplication
}

export function useContinueWithComputedIncome(): [
  MutationFunction<FilteredCreditApplication, { id: string; applicantType: string }>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: continueWithComputedIncome,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

export type SkipBankAccountRequestDto = {
  id: string
  applicantType: EApplicantType
}

const skipApplicantBankRequest: MutationFunction<FilteredCreditApplication, SkipBankAccountRequestDto> = async (
  dto: SkipBankAccountRequestDto,
) => {
  const response = await apiClient.post(`api/creditApplication/${dto.id}/skipbankaccountrequest/${dto.applicantType}`)
  return response.data as FilteredCreditApplication
}

export function useSkipApplicantBankRequest(): [
  MutationFunction<FilteredCreditApplication, SkipBankAccountRequestDto>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: skipApplicantBankRequest,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

export type CompleteBankAccountRequestDto = {
  id: string
  externalServiceId: string
  applicantType: EApplicantType
}

const completeApplicantFlinks: MutationFunction<RequiredExternalStep, CompleteBankAccountRequestDto> = async (
  dto: CompleteBankAccountRequestDto,
) => {
  const response = await apiClient.post(`api/creditApplication/${dto.id}/bankrequest/${dto.externalServiceId}/complete`)
  return response.data as RequiredExternalStep
}

export function useCompleteApplicantFlinks(): [
  MutationFunction<RequiredExternalStep, CompleteBankAccountRequestDto>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: completeApplicantFlinks,
    onSuccess: (data, variables) => {
      const updatedData = {
        ...queryClient.getQueryData(keysFactory.detail(variables.id)),
      } as FilteredCreditApplication
      const indexRequiredStep = updatedData.requiredExternalSteps.findIndex((x) => x.id === data.id)

      if (indexRequiredStep >= 0) updatedData.requiredExternalSteps[indexRequiredStep] = data

      queryClient.setQueryData(keysFactory.detail(variables.id), updatedData)
      return updatedData
    },
  })

  return [mutateAsync, isPending, reset]
}

const retryHardHit: MutationFunction<FilteredCreditApplication, string> = async (id) => {
  const response = await apiClient.post(`api/creditApplication/${encodeURIComponent(id)}/RetryHardHit`)
  return response.data as FilteredCreditApplication
}

export function useRetryHardHit(): [MutationFunction<FilteredCreditApplication, string>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation<FilteredCreditApplication, unknown, string>({
    mutationFn: retryHardHit,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

export type CanHaveSupplementalLoanDto = {
  ongoingApplicationReferenceNumber: number
  canHaveSupplementalLoan: boolean
  refusalReasonCodes: ESupplementalLoanRefusalReasons[]
}

const getCanHaveSupplementalLoanDto = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['supplementalLoan']>>) => {
  const response = await apiClient.get<CanHaveSupplementalLoanDto>(
    `/api/creditApplication/${encodeURIComponent(id)}/GetCanHaveSupplementalLoan`,
  )
  return response.data
}

export function useGetCanHaveSupplementalLoanDto(
  creditApplicationId: string,
): [CanHaveSupplementalLoanDto | null, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.supplementalLoan(creditApplicationId),
    queryFn: getCanHaveSupplementalLoanDto,
    enabled: !!creditApplicationId,
  })

  return [data ?? null, isFetching]
}
