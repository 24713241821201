import { yupResolver } from '@hookform/resolvers/yup'
import { SelectInput } from '@src/components'
import Alert from '@src/components/Alert'
import { Constants, EJobType, EJobTypeList, EOtherIncomeTypeList, EOtherIncomeTypes } from '@src/types'
import { range } from 'lodash-es'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { formatPhonesNumber, helpTip, phoneFormat, removePhoneFormat } from '../../../services/utils'
import { EmploymentInput, EmploymentInputSchema } from './PrequalificationSchema'
import { FORM_ID } from './StepperFormFooter'

type Props = {
  applicantData: EmploymentInput
  onApplicantUpdated: (data: EmploymentInput) => void
}

const EmploymentForm = ({ onApplicantUpdated, applicantData }: Props) => {
  const { t } = useTranslation()
  const [displayMinIncomeMessage, setDisplayMinIncomeMessage] = React.useState(false)

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm<EmploymentInput>({
    mode: 'onBlur',
    resolver: yupResolver(EmploymentInputSchema),
    defaultValues: applicantData,
  })

  const watchOtherIncomeTypes = watch('otherIncomes.0.typeId')
  const jobType = watch('currentJobs.0.jobType')
  const currentJobSalary = watch('currentJobs.0.annualSalary')
  const otherIncomeAnnualSalary = watch('otherIncomes.0.annualAmount')
  const watchYears = watch('currentJobs.0.years')
  const watchYearMonths = watch('currentJobs.0.yearMonths')

  const onSubmit = (payload: EmploymentInput) => {
    if (Number(currentJobSalary ?? 0) + Number(otherIncomeAnnualSalary ?? 0) >= Constants.MinIncomes) {
      onApplicantUpdated(payload)
    } else {
      setDisplayMinIncomeMessage(true)
    }
  }

  React.useEffect(() => {
    const years = +(watchYears ?? 0)
    const months = +(watchYearMonths ?? 0)
    const total = years * 12 + months
    setValue('currentJobs.0.months', total)
  }, [setValue, watchYearMonths, watchYears])

  React.useEffect(() => {
    formatPhonesNumber()
    helpTip()
  }, [applicantData])

  const handlPhoneOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target
    const phone = removePhoneFormat(value)
    setValue(id as 'currentJobs.0.employerPhone', phone, { shouldValidate: true })
  }
  const handlPhoneExtOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target
    const ext = removePhoneFormat(value)
    setValue(id as 'currentJobs.0.employerPhoneExt', ext, { shouldValidate: true })
  }

  React.useEffect(() => {
    setValue('otherIncomes.0.jobType', jobType)
  }, [jobType, setValue])

  React.useEffect(() => {
    if (!watchOtherIncomeTypes) {
      setValue('otherIncomes.0.annualAmount', null)
    }
  }, [setValue, watchOtherIncomeTypes])

  return (
    <section className="step-content">
      <h3 className="form-question">{t('income.howMuch')}</h3>

      <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
        {displayMinIncomeMessage && <Alert type="warn" message={t('income.minAnnualSalary')} />}
        <SelectInput
          id="employment_category"
          label="income.category"
          error={errors.currentJobs?.[0]?.jobType}
          hasEmptyValue
          disableEmptyValue
          optionsFromEnum={EJobTypeList}
          optionsRootLabel="enum.eJobType"
          {...register('currentJobs.0.jobType')}
        />

        {(Number(jobType) as EJobType) !== EJobType.Unemployed && (
          <>
            <div className={`control-group  ${errors.currentJobs && errors.currentJobs[0]?.annualSalary && 'error'}`}>
              <label htmlFor="gross_anual">
                {t('income.beforeDeductions')}
                <span className="help-tip">
                  <p>{t('income.grossAnnualInfo')}</p>
                </span>
              </label>
              <div className="number-wrap">
                <input
                  type="number"
                  title={t('income.grossAnnualInfo')}
                  id="annualSalary"
                  {...register('currentJobs.0.annualSalary')}
                />
              </div>
            </div>
            <div className={`control-group ${errors.currentJobs && errors.currentJobs[0]?.jobTitle && 'error'}`}>
              <label htmlFor="job_title">{t('income.jobTitle')}</label>
              <input type="text" id="jobTitle" {...register('currentJobs.0.jobTitle')} maxLength={50} />
            </div>

            <div
              className={`control-group mid ${errors.currentJobs && errors.currentJobs[0]?.employerName && 'error'}`}
            >
              <label htmlFor="employer_name">{t('income.employerName')}</label>
              <input type="text" id="employerName" {...register('currentJobs.0.employerName')} maxLength={100} />
            </div>

            <div
              className={`control-group sml ${errors.currentJobs && errors.currentJobs[0]?.employerPhone && 'error'}`}
            >
              <label htmlFor="currentJobs.0.employerPhone">{t('income.phoneNumber')}</label>
              <input
                type="tel"
                placeholder="( ___ ) ___-____"
                className="phone-input"
                id="currentJobs.0.employerPhone"
                defaultValue={phoneFormat(getValues('currentJobs.0.employerPhone') as string)}
                onBlur={handlPhoneOnchange}
              />
            </div>
            <div
              className={`control-group sml ${
                errors.currentJobs && errors.currentJobs[0]?.employerPhoneExt && 'error'
              }`}
            >
              <label htmlFor="currentJobs.0.employerPhoneExt">{t('income.extension')}</label>
              <input
                type="text"
                inputMode="tel"
                placeholder="( ____ )"
                id="currentJobs.0.employerPhoneExt"
                onBlur={handlPhoneExtOnchange}
                maxLength={10}
              />
            </div>

            <div className="control-group mid">
              <label htmlFor="employment_years">{t('income.employmentLenght')}</label>
              <div className="dropdown-wrap">
                <select id="year" {...register('currentJobs.0.years')}>
                  <option value={0}>{t('common.years')}</option>
                  {range(1, 12).map((item) => (
                    <option value={item} key={item}>
                      {t('common.yearsLabel', { count: item })}
                    </option>
                  ))}
                  <option value={12}>
                    {t('common.moreThan')} {t('common.yearsLabel', { count: 12 })}
                  </option>
                </select>
              </div>
            </div>
            <div className="control-group mid">
              <div className={`dropdown-wrap ${errors.currentJobs && errors.currentJobs[0]?.months && 'error'}`}>
                <select id="month" {...register('currentJobs.0.yearMonths')}>
                  <option value={0}>{t('common.months')}</option>
                  {range(1, 12).map((item) => (
                    <option value={item} key={item}>
                      {t('common.monthsLabel', { count: item })}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}

        <div className="control-group">
          <h1 className="h3"> {t('income.otherIncome')}</h1>
          <span className="help-tip">
            <p>{t('income.otherIncomeInfo')}</p>
          </span>
        </div>
        <div className="control-group mid">
          <label htmlFor="otherIncomeType">{t('income.otherIncome')}</label>
          <div className={`dropdown-wrap ${errors.otherIncomes && errors.otherIncomes[0]?.typeId && 'error'}`}>
            <select id="otherIncomeType" {...register('otherIncomes.0.typeId')}>
              <option aria-label={t('common.none')}> </option>
              {EOtherIncomeTypeList.map((item) => (
                <option key={item} value={item}>
                  {t(`enum.eOtherIncomeTypes.${item as EOtherIncomeTypes}`)}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className={`control-group mid ${errors.otherIncomes && errors.otherIncomes[0]?.annualAmount && 'error'}`}>
          <label htmlFor="other_source">{t('income.annualIncome')}</label>
          <input
            type="number"
            id="annualAmount"
            disabled={!watchOtherIncomeTypes}
            {...register('otherIncomes.0.annualAmount')}
          />
        </div>

        {(Number(watchOtherIncomeTypes) as EOtherIncomeTypes) === EOtherIncomeTypes.other && (
          <div className={`control-group ${errors.otherIncomes && errors.otherIncomes[0]?.description && 'error'}`}>
            <label htmlFor="other_source">{t('income.otherIncomeDescription')}</label>
            <input type="text" id="description" {...register('otherIncomes.0.description')} maxLength={50} />
          </div>
        )}
      </form>
    </section>
  )
}

export default EmploymentForm
